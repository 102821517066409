/*
*
* Get JS dep with Browserify
*
 */

global.jQuery = window.$ = require('jquery');
var slick = require('slick-carousel');
var imagesLoaded = require('imagesloaded');
require('jquery-lazyload');
var Sharer = require('sharer.npm.js');
require('fancybox')($);



imagesLoaded.makeJQueryPlugin( $ );


/*
*
* Declare the app as an object
*
*/

var app = window.app = {


    init: function() {
      app.menuMobileHandler();
      app.mainShareHandler();
      app.addIEVersionToBody();
      app.handlePrettyPhotoLink();
    },
    handlePrettyPhotoLink : function () {
      if($('[data-rel^="prettyPhoto"]').length){
        $('[data-rel^="prettyPhoto"]').each(function () {
          $(this).attr('data-rel', 'prettyPhoto[pp_gal]');
          $(this).attr('rel', 'prettyPhoto[pp_gal]');
        });
      }
    },
    getIEVersion : function () {

        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        var check = '';
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
        {
            check = parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)));
        }

        return check;
    },
    isIE : function () {
      return !!this.getIEVersion();
    },
    addIEVersionToBody : function (){
      if(this.isIE()){
        $('html').addClass('ie-'+this.getIEVersion());
      }

    },
    fullscreenHandler : function (slider) {
      $(document).keyup(function(e) {
          if (e.keyCode == 27) { // escape key maps to keycode `27`
              e.preventDefault();
              if($('body').hasClass('fullscreen')){
                $('#slider-control-fullscreen').trigger('click');
              }

          }
      });
      if (document.addEventListener)
        {
            document.addEventListener('webkitfullscreenchange', changeFsHandler, false);
            document.addEventListener('mozfullscreenchange', changeFsHandler, false);
            document.addEventListener('fullscreenchange', changeFsHandler, false);
            document.addEventListener('MSFullscreenChange', changeFsHandler, false);
        }

        function changeFsHandler()
        {
            if (document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement !== null)
            {
                $('body').toggleClass('fullscreen');
                $('body').addClass('loading');
                setTimeout(function () {
                  var size = $('body').hasClass('fullscreen') ? 'full':'window';
                  if($('#slider-control-play').hasClass('playing')){
                    var autoplay = true;
                  }
                  app.singleProject.sliderOpts.initialSlide = app.singleProject.currentSlide;
                  setTimeout(function () {

                    app.singleProject.slickInit($('.slider'), app.singleProject.currentSlide, autoplay);
                    app.singleProject.resizeSlider(size);
                    $('body').removeClass('loading');
                  });
                }, 1000);
            }
        }

    },
    toggleFullScreen : function () {

      if ((document.fullScreenElement && document.fullScreenElement !== null) ||
       (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }



    },
    mainShareHandler : function () {
      $('.icon-share').on('click', function (e) {
        e.preventDefault();

        $(this).next().toggleClass('opened');
      });
      $('.sharer').on('click', function (e){
        var sharer = new Sharer(e.target);
        sharer.share();
      });
    },
    onResize: function () {

    },
    menuMobileHandler: function () {
      $('#toggle-menu-mobile').on('click', function (e) {
        e.preventDefault();
        $('body').toggleClass('menu-opened');
      });
    },
    home: {
      gridAnimating: false,
      init: function () {
        this.homeFilters();
        if(window.location.hash){
          this.handleHash();
        }
        else {
          this.gridResizeItems();
        }
        $('.grid').bind('contextmenu', function(e) {
            return false;
        });

        // if(window.location.href.indexOf("?goto") > -1){
        //   var index = window.location.href.split('?goto=')[1];
        //   $('html, body').animate({
        //     scrollTop: $('.grid .item').index(index).offset().top
        //   });
        // }

        $(window).on('resize', function () {
          app.home.gridResizeItems();
        });

      },
      handleHash : function () {

          var hash = window.location.hash.substring(1);
          var menuItem = $('.sub-menu a[data-page-id='+hash+']')
          var href = menuItem.attr('href');
          $('#filters-menu li').removeClass('current-menu-item');
          menuItem.parent().addClass('current-menu-item');
          if(!app.home.gridAnimating){
            app.home.gridAnimOut($('.grid'), href);
          }

      },
      homeFilters : function () {
        var menuContainer = $('#filters-menu'),
            menuItems = $('#filters-menu a'),
            grid = $('.grid');

        menuItems.on('click', function(e) {
          e.preventDefault();
          var href = $(this).attr('href');
          var id = $(this).attr('data-page-id');
          var url = id > 0 ? '#'+id : ' ';
          if($('.page-template-page-grid-photos').length){

            $('#filters-menu li').removeClass('current-menu-item');
            $(this).parent().addClass('current-menu-item');
            window.history.pushState('', '', url);
            if(!app.home.gridAnimating){
              app.home.gridAnimOut(grid, href);
            }
            $('body').removeClass('menu-opened');
          }
          else {
            window.location.href = href;
          }



        });
      },
      gridResizeItems: function () {
        var baseTime = 0,
        duration = 100,
        imgs = $('.grid img'),
        imgSrc = imgs.map(function () {
          return $(this).attr('src');
        }),
        imgsLength = imgs.length,
        count = 0;


        loadImage(imgs[count]);


        function loadImage (img){
          $(img).imagesLoaded( function() {

            $(img).parent().removeClass('out');

            $(img).parent().parent().height($(img).height());
            if(count < imgsLength-1){
              count++;
              loadImage(imgs[count]);
            }
          });
        }

      },
      gridAnimOut: function (grid,href) {
        //grid.addClass('out');
        var baseTime = 0,
        duration = 100,
        count = grid.find('.item').length;
        app.home.gridAnimating = true;
        $(grid.find('.item').get().reverse()).each(function(index) {
          var self = $(this);
          self.addClass('out');

        });
        setTimeout(function() {
          app.home.gridAnimIn(grid, href);
        }, baseTime);
      },
      gridAnimIn: function (grid, url) {
        //grid.addClass('out');
        var count = grid.find('.item').length;
        $.get(url, function(response) {
           var html = $(response).find('.grid').html();
           html = html.split('class="item').join('class="out item');
           grid.html(html);
           setTimeout(function() {
               app.home.gridResizeItems();
               app.home.gridAnimating = false;
           }, 300);
        });

      }
    },
    singleProject: {
      sliderOpts : {},
      currentSlide : 0,
      slider: {},
      init: function () {
        var initialSlide;
        if(window.location.hash){
          initialSlide = parseInt(window.location.hash.substring(1));
        }
        else if(typeof INITIAL_SLIDE === 'number'){
          initialSlide = INITIAL_SLIDE;
        }
        else {
          initialSlide = $('.'+INITIAL_SLIDE).index();
        }
        $('.slide').bind('contextmenu', function(e) {
            return false;
        });
        this.resizeSlider();
        this.slickInit($('.slider'), initialSlide);
        app.fullscreenHandler(app.singleProject.slider);
      },
      resizeSlider : function (size){
        if(size === 'full'){
          $('.slider, .slide').height($(window).height()*90/100);
          $('.slide').css('max-height', $(window).height()*90/100);
        }
        else {
          $('.slider, .slide').height($(window).height()*75/100);
          $('.slide').css('max-height', $(window).height()*75/100);
        }
      },
      slickInit: function (container, initial, autoplay) {

        if(container.hasClass('slick-initialized')){
          container.slick('unslick');
        }
        var countInitial = initial;
        this.sliderOpts = {
          arrows: true,
          centerMode: true,
          accessibility: true,
          dots: false,
          initialSlide: initial,
          prevArrow: $('#slider-control-prev'),
          nextArrow: $('#slider-control-next'),
          centerPadding: 0,
          slidesToShow: 1,
          speed: 1000,
          autoplay: autoplay,
          autoplaySpeed: 4500,
          fade: true,
          cssEase: 'ease',
          lazyLoad: 'ondemand'
        };
        var slider,
        totalSlides = $('.slide').length;

        container.on('init', function(){
          $('#share-container button').each(function() {
            var that = $(this);
            that.attr('data-title', $('.slick-active').attr('data-share-title'));
            that.attr('data-url', $('.slick-active').attr('data-share-url'));
          });

            $('.slider-count-current').text(initial + 1);
            app.singleProject.currentSlide = countInitial;
        });
        slider = container.slick(app.singleProject.sliderOpts);
        app.singleProject.slider = slider;
        $(document).on('click', '#slider-control-play.playing',function (e) {
          e.preventDefault();
          slider.slick('slickPause');
          $(this).addClass('paused');
          $(this).removeClass('playing');
        });
        $(document).on('click', '#slider-control-play.paused' , function (e) {
          e.preventDefault();
          slider.slick('slickPlay');
          $(this).removeClass('paused');
          $(this).addClass('playing');
        });
        container.on('reInit afterChange', function(event, slick, currentSlide, nextSlide){

            var i = currentSlide;
            app.singleProject.currentSlide = i;
            window.history.pushState(null, null, '#'+app.singleProject.currentSlide);

            $('.slider-count-current').text(i+1);
            $('#share-container button').each(function() {
              var that = $(this);
              that.attr('data-title', $('.slick-active').attr('data-share-title'));
              that.attr('data-url', $('.slick-active').attr('data-share-url'));
            });
            $('#slider-control-back').attr('data-current-slide', i);
        });
        $('.slider-count-total').html($('.project').length);
        $('.slider-controls a ').on('click', function (e){
          e.preventDefault();
        });
        $('#slider-control-back').on('click', function (e) {

          var url = $(this).attr('data-target');
          window.location.href = url;
        });
        $('#slider-control-fullscreen').on('click', function (e) {
          e.preventDefault();
          container.addClass('hidden');
          app.singleProject.sliderOpts.initialSlide = app.singleProject.currentSlide;
          slider.slick('unslick');





          container.removeClass('hidden');

          app.toggleFullScreen();



        });
        $(document).on('keydown', function (e) {
          var event = window.event ? window.event : e;
          if(event.keyCode === 37){
            slider.slick('slickPrev');
          }
          if(event.keyCode === 39){
            slider.slick('slickNext');
          }
        });
      }

    }
};


/*
*
* Wait for DOM ready and init() the app
*
*/

$(document).ready(function () {
	// DOM is ready
  app.init();
  if($('.page-template-page-grid-photos, .page-template-page-grid-photos-reportage').length ){
    app.home.init();
  }
  if($('.single-project, .single-photo_reportage').length){
    app.singleProject.init();
  }
});
